import styles from './popup.module.css';
import { CloseOutlined } from '@mui/icons-material';

interface PopupCustomStyle {
  wrapperColor?: string;
  contentBgrColor?: string;
  popupMargin?: string;
}

interface PopupPropType {
  children: React.ReactNode;
  close: () => void;
  isHandleBlur?: boolean;
  showCloseButton?: boolean;
  customStyle?: PopupCustomStyle;
}

export const Popup = (props: PopupPropType) => {
  const {
    children,
    close,
    isHandleBlur = true,
    showCloseButton = true,
    customStyle,
  } = props;
  const {
    wrapperColor = '#0008',
    contentBgrColor = '#fff',
    popupMargin = 'auto',
  } = customStyle || {};
  const handleBlur = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (isHandleBlur && e.target === e.currentTarget) {
      close();
    }
  };

  return (
    <div
      className={styles.popup_wrapper}
      onClick={handleBlur}
      style={{ backgroundColor: wrapperColor }}
    >
      <div
        className={styles.popup_content}
        style={{ backgroundColor: contentBgrColor, margin: popupMargin }}
      >
        {showCloseButton && (
          <div className={styles.popup_close} onClick={close}>
            <CloseOutlined sx={{ fontWeight: '700' }} />
          </div>
        )}
        {children}
      </div>
    </div>
  );
};
