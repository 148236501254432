import { ProposalType, StreamType, TaskType } from 'constant/enum';
import {
  ApproveTaskAction,
  CompleteStreamTaskAction,
  ConfirmTaskAction,
  DynamicStreamAction,
} from './ActionButton';

export interface ActionPropType {
  streamId: string;
}

export interface StreamActionPropType extends ActionPropType {
  type: number;
  missionType: number;
  isEnd: number;
  option: number;
  optionValue: number;
  canUpdate: boolean;
  isAssignee: boolean;
  isTheLastStream: boolean;
}

const TaskStreamAction = (props: StreamActionPropType) => {
  const { missionType } = props;
  // if is approve task
  if (missionType === TaskType.APPROVE_PROPOSAL) {
    return <ApproveTaskAction {...props} />;
  }

  if (missionType === TaskType.CONFIRM_REQUEST) {
    return <ConfirmTaskAction {...props} />;
  }

  if (
    missionType === TaskType.EXECUTE_PROPOSAL ||
    missionType === TaskType.PROCESS_PAYMENT
  ) {
    return <CompleteStreamTaskAction {...props} />;
  }

  if (missionType === TaskType.DYNAMIC) {
    return <DynamicStreamAction {...props} />;
  }

  return <></>;
};

const ProposalStreamAction = (props: StreamActionPropType) => {
  const { missionType } = props;

  if (missionType === ProposalType.DYNAMIC) {
    return <DynamicStreamAction {...props} />;
  }

  return <></>;
};

export const StreamAction = (props: StreamActionPropType) => {
  const { type } = props;
  // if is task
  if (type === StreamType.TASK) {
    return <TaskStreamAction {...props} />;
  }

  if (type === StreamType.PROPOSAL) {
    return <ProposalStreamAction {...props} />;
  }

  return <></>;
};
