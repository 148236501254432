export interface StreamFollowInfoDataType {
  id: string;
  description?: string;
  creatorName: string;
  creatorAvatar?: string;
  createAt: string;
  groupList: string[];
  numberOfEnd?: number;
  numberOfStep?: number;
  isDone?: boolean;
}

export interface GroupInfoDataType {
  id: string;
  name: string;
}

export interface StreamInfoListDataType {
  streamInfoList: StreamFollowInfoDataType[];
  groupList: GroupInfoDataType[];
}

export const enum ListType {
  PROPOSAL = 1,
  REQUEST = 2,
  TASK = 3,
}

export const LIST_INFO = {
  [ListType.PROPOSAL]: {
    vi: 'đề xuất',
    en: 'proposal',
  },
  [ListType.REQUEST]: {
    vi: 'đề nghị',
    en: 'request',
  },
  [ListType.TASK]: {
    vi: 'task',
    en: 'task',
  },
};
