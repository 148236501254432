import { Link } from 'react-router-dom';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export const CreateStreamButton = () => {
  return (
    <>
      <Link
        to='/create-stream'
        style={{
          borderRadius: '50%',
          position: 'fixed',
          left: '20px',
          bottom: '20px',
        }}
      >
        <AddCircleIcon
          sx={{
            fontSize: '70px',
            cursor: 'pointer',
            color: '#141D49',

            '@media (max-width: 480px)': {
              fontSize: '50px',
            },
          }}
        />
      </Link>
    </>
  );
};
