import styled from '@emotion/styled';
import { CloseOutlined } from '@mui/icons-material';
import { Button, Form, Input } from 'antd';
import { postRequest, ResponseDataType } from 'api';
import { Popup, popupStyles } from 'Components';
import { useAppContext } from 'Context/AppContext';
import { MenuItemType } from '../type';
import { toast } from 'react-toastify';
import { MenuItemKey } from '../const';

export enum CreateType {
  GROUP = 'group',
  DEPARTMENT = 'department',
}

const createInfo = {
  [CreateType.GROUP]: {
    title: 'group',
    path: 'hr-create-group',
    menuKeyIndex: 2,
    menuItemKey: MenuItemKey.GROUP,
  },
  [CreateType.DEPARTMENT]: {
    title: 'phòng ban',
    path: 'hr-create-department',
    menuKeyIndex: 1,
    menuItemKey: MenuItemKey.DEPARTMENT,
  },
};

interface CreateNewPopupPropsType {
  close: () => void;
  type: CreateType;
  setMenuItems: React.Dispatch<React.SetStateAction<MenuItemType[]>>;
  handleChangeMenuItem: (keyPath: string[]) => Promise<void>;
}

export const CreateNewPopup = ({
  type,
  close,
  setMenuItems,
  handleChangeMenuItem,
}: CreateNewPopupPropsType) => {
  const { currentOrg } = useAppContext();
  const [form] = Form.useForm();

  const handleCreateNew = async ({ name }: { name: string }) => {
    console.log('end');
    const response: ResponseDataType<{
      id: string;
      name: string;
    }> = await postRequest(`/organization/${createInfo[type].path}`, {
      organizationId: currentOrg,
      name,
    });

    if (response.code) {
      if (typeof response.message === 'object') {
        response.message.forEach((message: string) => toast.error(message));
      } else toast.error(response.message);
      close();

      return;
    }

    if (response.data) {
      const newGroup = {
        key: response.data.id,
        label: response.data.name,
      };
      toast.success(`Thêm ${createInfo[type].title} mới thành công`);
      setMenuItems((prev) => {
        const index = createInfo[type].menuKeyIndex;
        const createItem = prev[index].children?.pop();
        prev[index].children?.push(newGroup);
        if (createItem) {
          prev[index].children?.push(createItem);
        }

        console.log(prev);
        return [...prev];
      });
      handleChangeMenuItem([response.data.id, createInfo[type].menuItemKey]);
    }
    close();
  };

  if (!createInfo[type]) {
    close();
    return <></>;
  }

  return (
    <Popup
      close={close}
      isHandleBlur={false}
      showCloseButton={false}
      customStyle={{
        contentBgrColor: '#fbfbfb',
        wrapperColor: '#fbfbfb',
        popupMargin: '50px auto auto auto',
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: 410,
          marginBottom: 30,
        }}
      >
        <div style={{ fontWeight: 600, fontSize: 30 }}>
          Thêm {createInfo[type].title}
        </div>
        <div onClick={close} style={{ height: 35, cursor: 'pointer' }}>
          <CloseOutlined sx={{ fontSize: 35 }} />
        </div>
      </div>
      <Form form={form} onFinish={handleCreateNew}>
        <FormItemLable>Tên {createInfo[type].title}</FormItemLable>
        <Form.Item name='name' style={{ margin: 0 }}>
          <Input
            placeholder={`Tên ${createInfo[type].title}`}
            style={{ padding: 10, width: 410 }}
          />
        </Form.Item>
        <Button
          type='primary'
          className={popupStyles.form_button}
          style={{ width: 410, marginTop: 60 }}
          htmlType='submit'
        >
          Thêm
        </Button>
      </Form>
    </Popup>
  );
};

const FormItemLable = styled.div`
  font-weight: 700;
  font-size: 16px;
  margin: 20px 0 7px 0;
`;
