import styled from '@emotion/styled';
import { CloseOutlined } from '@mui/icons-material';
import { Button, Form, Input, Row, Select } from 'antd';
import { getRequest, postRequest, ResponseDataType } from 'api';
import { Avatar, Popup, popupStyles } from 'Components';
import { useAppContext } from 'Context/AppContext';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getFullInfoInDepartment, modalConfirm, normalizeText } from 'utils';

interface ContinueDynamicStreamPopupPropsType {
  close: () => void;
}

interface MemberDepartmentInfo {
  name: string;
  namecode: string;
  isManager: boolean;
}

interface MemberInfoInList {
  id: string;
  name: string;
  avatar: string;
  departmentInfo: MemberDepartmentInfo;
}

export const ContinueDynamicStreamPopup = (
  props: ContinueDynamicStreamPopupPropsType,
) => {
  const { close } = props;
  const [form] = Form.useForm();
  const [memberList, setMemberList] = useState<MemberInfoInList[]>([]);
  const { currentOrg } = useAppContext();
  const { streamId: rootId } = useParams();

  useEffect(() => {
    const getMemberList = async () => {
      const response: ResponseDataType<{
        managerList: string[];
        memberList: MemberInfoInList[];
      }> = await getRequest(
        `/organization/get-org-member-list?orgId=${currentOrg}&type=all&getPosition=true`,
      );

      if (!response.data) {
        setMemberList([]);
        return;
      }

      const resData = response.data;
      const sortedMemberList = resData.memberList.reduce(
        (curList: MemberInfoInList[], member) => {
          if (resData.managerList.includes(member.id)) {
            curList.unshift(member);
          } else {
            curList.push(member);
          }
          return curList;
        },
        [],
      );
      setMemberList(sortedMemberList);
    };

    getMemberList();
  }, [currentOrg]);

  const handleContinueDynamicStream = (value: {
    assignee: string;
    title?: string;
    content: string;
  }) => {
    modalConfirm({
      title: 'Xác nhận tiếp tục luồng với thông tin vừa nhập?',
      async onOk() {
        const response: ResponseDataType<{ id: string }> = await postRequest(
          '/stream/continue-dynamic-stream',
          {
            rootId,
            ...value,
          },
        );

        if (response.code) {
          if (typeof response.message === 'object') {
            response.message.forEach((message: string) => toast.error(message));
          } else toast.error(response.message);
        } else {
          toast.success('Tiếp tục luồng thành công');
          close();
        }
      },
    });
  };

  return (
    <Popup
      close={close}
      isHandleBlur={false}
      showCloseButton={false}
      customStyle={{
        contentBgrColor: '#fbfbfb',
        wrapperColor: '#fbfbfb',
        popupMargin: '50px auto auto auto',
      }}
    >
      <>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: 410,
            // marginBottom: 10,
          }}
        >
          <div style={{ fontWeight: 600, fontSize: 30 }}>Thêm việc</div>
          <div onClick={close} style={{ height: 35, cursor: 'pointer' }}>
            <CloseOutlined sx={{ fontSize: 35 }} />
          </div>
        </div>
        <Form form={form} onFinish={handleContinueDynamicStream}>
          <FormItemLable>Giao cho</FormItemLable>
          <Form.Item
            name='assignee'
            rules={[
              {
                required: true,
                message: 'Cần chọn người nhận công việc!',
              },
            ]}
            style={{ marginBottom: 0 }}
          >
            <Select
              placeholder='Người thực hiện'
              optionFilterProp='label'
              showSearch={true}
              filterOption={(input, option) =>
                normalizeText(option?.label?.toString()).includes(
                  normalizeText(input),
                ) ||
                normalizeText(option?.key?.toString()).includes(
                  normalizeText(input),
                )
              }
              style={{ height: 44 }}
            >
              {memberList.map((member, index) => {
                const memberPosition = getFullInfoInDepartment({
                  isOldInfo: false,
                  isManager: member.departmentInfo.isManager,
                  department: { ...member.departmentInfo },
                });

                return (
                  <Select.Option
                    key={`${index}-${memberPosition}`}
                    value={member.id}
                    label={member.name}
                  >
                    <Row>
                      <Avatar
                        src={member.avatar}
                        style={{ width: 30, height: 30, borderRadius: '50%' }}
                      />
                      <div style={{ lineHeight: 'normal', marginLeft: 10 }}>
                        <div>{member.name}</div>
                        <div style={{ fontSize: 11, fontWeight: 300 }}>
                          {memberPosition}
                        </div>
                      </div>
                    </Row>
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
          <FormItemLable>Tiêu đề</FormItemLable>
          <Form.Item name='title' style={{ margin: 0 }}>
            <Input placeholder='Tiêu đề' style={{ padding: 10 }} />
          </Form.Item>
          <FormItemLable>Nội dung</FormItemLable>
          <Form.Item
            name='content'
            rules={[
              {
                required: true,
                message: 'Cần nhập Nội dung công việc tiếp theo!',
              },
            ]}
            style={{ margin: 0 }}
          >
            <Input.TextArea
              placeholder='Nội dung'
              style={{ padding: 10, minHeight: 100 }}
            />
          </Form.Item>
          <Button
            type='primary'
            className={popupStyles.form_button}
            style={{ width: 410, marginTop: 60 }}
            htmlType='submit'
          >
            Thêm
          </Button>
        </Form>
      </>
    </Popup>
  );
};

const FormItemLable = styled.div`
  font-weight: 700;
  font-size: 16px;
  margin: 20px 0 7px 0;
`;
