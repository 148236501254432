import { ListType } from './type';

export const getListType = (pathname: string) => {
  if (pathname.includes('proposal-list')) {
    return ListType.PROPOSAL;
  }

  if (pathname.includes('request-list')) {
    return ListType.REQUEST;
  }

  return ListType.TASK;
};
