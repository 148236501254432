import {
  CloseOutlined,
  DomainOutlined,
  EmailOutlined,
  ImageOutlined,
  PermIdentityOutlined,
  StayPrimaryPortraitOutlined,
} from '@mui/icons-material';
import { Popup } from 'Components';
import { popupStyles } from 'Components';
import { useAppContext } from 'Context/AppContext';
import { Button, Form, Input, Row, Select } from 'antd';
import { ResponseDataType, postRequest } from 'api';
import { toast } from 'react-toastify';
import { modalConfirm, testImage } from 'utils';
import { DepartmentInfo, GroupInfo } from '../type';
import styled from '@emotion/styled';

interface FormValueType {
  email: string;
  name: string;
  phone: string;
  avatar?: string;
  department?: string;
  groups?: string[];
}

interface AddMemberPopupPropsType {
  close: () => void;
  departmentList: DepartmentInfo[];
  groupList: GroupInfo[];
}

export const AddMemberPopup = (props: AddMemberPopupPropsType) => {
  const { close, departmentList, groupList } = props;
  const [form] = Form.useForm();
  const { currentOrg } = useAppContext();

  const handleAddMember = async (value: FormValueType) => {
    modalConfirm({
      title: 'Xác nhận thêm nhân sự?',
      async onOk() {
        const response: ResponseDataType<{ id: string }> = await postRequest(
          '/organization/hr-add-member',
          {
            organizationId: currentOrg,
            email: value.email,
            name: value.name,
            phone: value.phone,
            avatar: value.avatar,
            departmentId: value.department,
            groupList: value.groups || [],
          },
        );

        if (response.code) {
          if (typeof response.message === 'object') {
            response.message.forEach((message: string) => toast.error(message));
          } else toast.error(response.message);
        } else {
          toast.success('Thêm thành viên mới thành công');
          close();
        }
      },
    });
  };

  return (
    <Popup
      close={close}
      isHandleBlur={false}
      showCloseButton={false}
      customStyle={{
        contentBgrColor: '#fbfbfb',
        wrapperColor: '#fbfbfb',
        popupMargin: '50px auto auto auto',
      }}
    >
      <>
        {/* <div className={popupStyles.popup_title}>Thêm thành viên</div> */}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: 410,
            marginBottom: 30,
          }}
        >
          <div style={{ fontWeight: 600, fontSize: 30 }}>Thêm thành viên</div>
          <div onClick={close} style={{ height: 35, cursor: 'pointer' }}>
            <CloseOutlined sx={{ fontSize: 35 }} />
          </div>
        </div>
        <Form form={form} onFinish={handleAddMember}>
          <div className={popupStyles.form}>
            <Form.Item
              name='email'
              rules={[
                { required: true, message: 'Cần nhập email!' },
                { type: 'email', message: 'Cần nhập email đúng định dạng!' },
              ]}
              style={{ margin: 0 }}
            >
              <Input
                prefix={
                  <EmailOutlined
                    className={popupStyles.form_input_prefix_icon}
                  />
                }
                placeholder='Email'
                className={popupStyles.form_input}
              />
            </Form.Item>
            <div className={popupStyles.line} />
            <Form.Item
              name='name'
              rules={[{ required: true, message: 'Cần nhập tên thành viên!' }]}
              style={{ margin: 0 }}
            >
              <Input
                prefix={
                  <PermIdentityOutlined
                    className={popupStyles.form_input_prefix_icon}
                  />
                }
                placeholder='Tên thành viên'
                className={popupStyles.form_input}
              />
            </Form.Item>
            <div className={popupStyles.line} />
            <Form.Item name='phone' style={{ margin: 0 }}>
              <Input
                prefix={
                  <StayPrimaryPortraitOutlined
                    className={popupStyles.form_input_prefix_icon}
                  />
                }
                placeholder='Số điện thoại'
                className={popupStyles.form_input}
              />
            </Form.Item>
            <div className={popupStyles.line} />
            <Form.Item
              name='avatar'
              rules={[
                () => ({
                  async validator(_, value) {
                    if (!value) return Promise.resolve();
                    var checkFlag;
                    try {
                      checkFlag = await testImage(value, 3000);
                    } catch (error) {
                      checkFlag = false;
                    }
                    if (checkFlag) {
                      return Promise.resolve();
                    } else return Promise.reject('Link avatar lỗi!');
                  },
                }),
              ]}
              style={{ margin: 0 }}
            >
              <Input
                prefix={
                  <ImageOutlined
                    className={popupStyles.form_input_prefix_icon}
                  />
                }
                placeholder='Avatar'
                className={popupStyles.form_input}
              />
            </Form.Item>
            <div className={popupStyles.line} />
            <Row style={{ width: 400, padding: 7 }}>
              <DomainOutlined />
              <Form.Item name='department' style={{ marginBottom: 0 }}>
                <FormItemSelect
                  placeholder='Phòng ban'
                  optionFilterProp='label'
                  showSearch={true}
                  style={{ marginBottom: 0, width: 340, marginLeft: 8 }}
                  allowClear
                  options={departmentList.map((d) => ({
                    value: d.id,
                    label: d.name,
                  }))}
                />
              </Form.Item>
            </Row>
            <div className={popupStyles.line} />
            <Row style={{ width: 400, padding: 7 }}>
              <DomainOutlined />
              <Form.Item name='groups' style={{ marginBottom: 0 }}>
                <FormItemSelect
                  mode='multiple'
                  placeholder='Group'
                  optionFilterProp='label'
                  showSearch={true}
                  style={{ marginBottom: 0, width: 340, marginLeft: 8 }}
                  allowClear
                  options={groupList.map((d) => ({
                    value: d.id,
                    label: d.name,
                  }))}
                />
              </Form.Item>
            </Row>
          </div>
          <div
            className={popupStyles.form_button_wrapper}
            style={{ marginTop: 50 }}
          >
            <Button
              type='primary'
              className={`${popupStyles.form_button} ${popupStyles.form_button_primary}`}
              htmlType='submit'
            >
              Thêm thành viên
            </Button>
            <Button
              type='primary'
              className={`${popupStyles.form_button} ${popupStyles.form_button_cancel}`}
              onClick={close}
            >
              Huỷ
            </Button>
          </div>
        </Form>
      </>
    </Popup>
  );
};

const FormItemSelect = styled(Select)`
  .ant-select-selector {
    border-width: 0 !important;
    padding: 0 4px !important;
  }
`;
