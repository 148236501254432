import { createContext, useContext, useEffect, useState } from 'react';
import {
  AttachedFileInfo,
  CommentInfoDataType,
  MemberInfoDataType,
} from '../Components';
import { ResponseDataType, getRequest } from 'api';
import { toast } from 'react-toastify';

interface StreamDetailProviderType {
  children: React.ReactNode;
  selectedStreamId: string;
  isStreamFollowEnd: number;
}

interface StreamDetailContextType {
  selectedStreamId: string;
  isStreamFollowEnd: number;
  commentList: CommentInfoDataType[];
  setCommentList: React.Dispatch<React.SetStateAction<CommentInfoDataType[]>>;
  myInfo: MemberInfoDataType | undefined;
  attachedFileList: AttachedFileInfo[] | undefined;
  setAttachedFileList: React.Dispatch<
    React.SetStateAction<AttachedFileInfo[] | undefined>
  >;
}

interface GetDetailInfoResDto {
  myInfo: MemberInfoDataType;
  commentList: CommentInfoDataType[];
  attachedFileList: AttachedFileInfo[];
}

const StreamDetailContext = createContext<StreamDetailContextType | null>(null);

export const StreamDetailProvider: React.FC<StreamDetailProviderType> = ({
  children,
  selectedStreamId,
  isStreamFollowEnd,
}) => {
  const [myInfo, setMyInfo] = useState<MemberInfoDataType>();
  const [commentList, setCommentList] = useState<CommentInfoDataType[]>([]);
  const [attachedFileList, setAttachedFileList] =
    useState<AttachedFileInfo[]>();

  useEffect(() => {
    setMyInfo(undefined);
    setCommentList([]);
    const getCommentList = async () => {
      const response: ResponseDataType<GetDetailInfoResDto> = await getRequest(
        `/stream/get-detail-info?streamId=${selectedStreamId}`
      );

      if (response.code) {
        toast.error(response.message);
      } else {
        setMyInfo(response.data?.myInfo);
        setCommentList(response.data?.commentList || []);
        setAttachedFileList(response.data?.attachedFileList || []);
      }
    };

    getCommentList();
  }, [selectedStreamId]);

  const contextValue = {
    selectedStreamId,
    isStreamFollowEnd,
    commentList,
    setCommentList,
    myInfo,
    attachedFileList,
    setAttachedFileList,
  };

  return (
    <StreamDetailContext.Provider value={contextValue}>
      {children}
    </StreamDetailContext.Provider>
  );
};

export const useStreamDetailInfo = () => {
  const context = useContext(StreamDetailContext);

  if (!context) {
    throw new Error('Stream Detail context is not available');
  }

  return context;
};
