import { Button, Form, Menu, Popover, Row } from 'antd';
import styles from '../hr.module.css';
import { useEffect, useState } from 'react';
import { MenuItemKey, defaultMenuItems } from './const';
import { ResponseDataType, deleteRequest, getRequest, postRequest } from 'api';
import { useAppContext } from 'Context/AppContext';
import { toast } from 'react-toastify';
import {
  DepartmentInfo,
  GroupInfo,
  MemberDetailInfoType,
  MemberInfoInList,
} from './type';
import { Avatar } from 'Components';
import styled from '@emotion/styled';
import {
  AddMemberPopup,
  CreateNewPopup,
  CreateType,
  MemberDetailInfo,
} from './Component';
import { MemberStatus } from 'constant/enum';
import { CircularProgress } from '@mui/material';
import { DepartmentNamecode } from 'constant/department';
import { modalConfirm } from 'utils';
import { AddCircleOutline, MoreHoriz } from '@mui/icons-material';

const CreateNewButton = ({ title }: { title: string }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        position: 'absolute',
        top: 0,
        left: 15,
      }}
    >
      <AddCircleOutline sx={{ marginRight: 1 }} />
      <span>{title}</span>
    </div>
  );
};

export const MemberList = () => {
  const [form] = Form.useForm();
  const { currentOrg, currentOrgInfo } = useAppContext();
  const [isEditing, setIsEditing] = useState(false);
  const [menuItems, setMenuItems] = useState(defaultMenuItems);
  const [selectedMenu, setSelectedMenu] = useState<string[]>([MenuItemKey.ALL]);
  const [memberList, setMemberList] = useState<MemberInfoInList[]>();
  const [departmentList, setDepartmentList] = useState<DepartmentInfo[]>([]);
  const [groupList, setGroupList] = useState<GroupInfo[]>([]);
  const [selectedMember, setSelectedMember] = useState<MemberDetailInfoType>();
  const [updating, setUpdating] = useState(false);
  const [openAddMemberPopup, setOpenAddMemberPopup] = useState(false);
  const [createPopupType, setCreatePopupType] = useState<CreateType>();

  useEffect(() => {
    const getOrgStructure = async () => {
      const response: ResponseDataType<{
        departmentList: DepartmentInfo[];
        groupList: GroupInfo[];
        allMemberList: MemberInfoInList[];
      }> = await getRequest(
        `/organization/get-org-structure?orgId=${currentOrg}`,
      );

      if (response.code) {
        toast.error(response.message);
      } else {
        setMenuItems((prev) => {
          prev[1].children = (response.data?.departmentList || []).map(
            (department) => ({
              key: department.id,
              label: department.name,
            }),
          );
          prev[2].children = (response.data?.groupList || []).map((group) => ({
            key: group.id,
            label: group.name,
          }));
          if (
            [DepartmentNamecode.CEO, DepartmentNamecode.HCNS].includes(
              currentOrgInfo?.memberDepartmentCode as DepartmentNamecode,
            )
          ) {
            prev[1].children.push({
              key: 'create-new-department',
              label: <CreateNewButton title='Thêm phòng ban' />,
            });
            prev[2].children.push({
              key: 'create-new-group',
              label: <CreateNewButton title='Thêm group' />,
            });
          }

          return [...prev];
        });
        setDepartmentList(response.data?.departmentList || []);
        setGroupList(response.data?.groupList || []);
        setMemberList(response.data?.allMemberList || []);
      }
    };

    getOrgStructure();
  }, [currentOrg, currentOrgInfo?.memberDepartmentCode]);

  const handleChangeMenuItem = async (keyPath: string[]) => {
    if (keyPath.length === 2 && keyPath[0].includes('create-new')) {
      setCreatePopupType(keyPath[0].split('-')[2] as CreateType);
      return;
    }

    setSelectedMenu(keyPath);
    setIsEditing(false);
    setMemberList(undefined);
    setSelectedMember(undefined);
    const response: ResponseDataType<{
      managerList: string[];
      memberList: MemberInfoInList[];
    }> = await getRequest(
      `/organization/get-org-member-list?orgId=${currentOrg}&type=${
        keyPath.length === 2 ? keyPath[1] : keyPath[0]
      }${keyPath.length === 2 ? '&relatedId=' + keyPath[0] : ''}`,
    );

    if (response.code) {
      toast.error(response.message);
      setMemberList([]);
    } else {
      if (!response.data) {
        setMemberList([]);
        return;
      }
      const resData = response.data;
      const sortedMemberList = resData.memberList.reduce(
        (curList: MemberInfoInList[], member) => {
          if (resData.managerList.includes(member.id)) {
            curList.unshift(member);
          } else {
            curList.push(member);
          }
          return curList;
        },
        [],
      );
      setMemberList(sortedMemberList);
    }
  };

  const handleSelectMember = async (memberId: string) => {
    // set để check loading
    setSelectedMember({
      id: '',
      name: '',
      email: '',
      status: MemberStatus.ACTIVE,
    });
    setIsEditing(false);
    const response: ResponseDataType<MemberDetailInfoType> = await getRequest(
      `/member/get-another-member-info?memberId=${memberId}`,
    );

    if (response.code) {
      toast.error(response.message);
      setSelectedMember(undefined);
    } else {
      setSelectedMember(response.data);
    }
  };

  const handleStartEdit = () => {
    if (!selectedMember) return;
    setIsEditing(true);
    form.setFieldsValue({
      name: selectedMember.name,
      phone: selectedMember.phone,
      department: selectedMember.department,
      role: selectedMember.role,
      groups: selectedMember.groupList,
      groupList,
    });
  };

  const handleUpdateMemberInfo = async (value: {
    name: string;
    phone: string;
    department: string;
    role: number;
    groups: string[];
  }) => {
    modalConfirm({
      title: 'Xác nhận cập nhật thông tin nhân sự?',
      async onOk() {
        setUpdating(true);
        const { department, role, groups, name, phone } = value;
        const response: ResponseDataType<string> = await postRequest(
          '/member/hr-update-another-info',
          {
            memberId: selectedMember?.id,
            name,
            phone,
            departmentId: department,
            role,
            groupList: groups,
          },
        );

        if (response.code) {
          toast.error(response.message);
          setUpdating(false);
        } else {
          toast.success('Cập nhật thông tin nhân sự thành công');
          setUpdating(false);
          setIsEditing(false);
          setSelectedMember((prev) => {
            if (!prev) return prev;

            return {
              ...prev,
              name,
              phone,
              department,
              role,
              groupList: groups,
            };
          });
        }
      },
    });
  };

  const handleDeleteMember = async () => {
    modalConfirm({
      title: 'Xác nhận xóa thành viên?',
      async onOk() {
        const response: ResponseDataType<string> = await deleteRequest(
          `/member/hr-delete-member/${selectedMember?.id}`,
        );

        if (response.code) {
          toast.error(response.message);
        } else {
          toast.success('Xóa thành viên thành công');
          setSelectedMember(undefined);
          setMemberList((prev) =>
            prev?.filter((member) => member.id !== selectedMember?.id),
          );
        }
      },
    });
  };

  const handleDeactivateMember = async () => {
    modalConfirm({
      title: 'Xác nhận cho thành viên nghỉ việc?',
      async onOk() {
        const response: ResponseDataType<string> = await deleteRequest(
          `/member/hr-deactivate-member/${selectedMember?.id}`,
        );

        if (response.code) {
          toast.error(response.message);
        } else {
          toast.success('Cho thành viên nghỉ việc thành công');
          setSelectedMember(undefined);
          setMemberList((prev) =>
            prev?.filter((member) => member.id !== selectedMember?.id),
          );
        }
      },
    });
  };

  const popoverMemberMoreActionContent = (
    <>
      <ButtonInPopover onClick={handleDeleteMember}>
        Xóa thành viên
      </ButtonInPopover>
      <ButtonInPopover onClick={handleDeactivateMember}>
        Cho nghỉ thành viên
      </ButtonInPopover>
    </>
  );

  return (
    <div className={styles.page_wrapper}>
      {openAddMemberPopup && (
        <AddMemberPopup
          close={() => setOpenAddMemberPopup(false)}
          departmentList={departmentList}
          groupList={groupList}
        />
      )}
      <Row align='middle'>
        <div className={styles.page_title}>Danh sách nhân sự</div>
        {[DepartmentNamecode.CEO, DepartmentNamecode.HCNS].includes(
          currentOrgInfo?.memberDepartmentCode as DepartmentNamecode,
        ) && (
          <div onClick={() => setOpenAddMemberPopup(true)}>
            <AddCircleOutline
              style={{
                margin: '0 0 10px 30px',
                fontSize: 35,
                cursor: 'pointer',
              }}
            />
          </div>
        )}
      </Row>
      <Row>
        <Menu
          style={{ width: 'max-content', minWidth: 250 }}
          items={menuItems}
          mode='inline'
          onSelect={(e) => handleChangeMenuItem(e.keyPath)}
          defaultSelectedKeys={[MenuItemKey.ALL]}
          selectedKeys={selectedMenu}
        />
        {createPopupType && (
          <CreateNewPopup
            close={() => setCreatePopupType(undefined)}
            type={createPopupType}
            setMenuItems={setMenuItems}
            handleChangeMenuItem={handleChangeMenuItem}
          />
        )}
        <MemberListWrapper>
          {memberList ? (
            memberList.map((member, index) => (
              <MemberInfoWrapper
                style={
                  selectedMember?.id === member.id
                    ? { backgroundColor: '#bbb' }
                    : {}
                }
                onClick={() => handleSelectMember(member.id)}
                key={index}
                align='middle'
              >
                <Avatar
                  src={member.avatar}
                  style={{
                    height: 35,
                    width: 35,
                    borderRadius: '50%',
                    marginRight: 10,
                  }}
                />
                <MemberName>{member.name}</MemberName>
              </MemberInfoWrapper>
            ))
          ) : (
            <Row justify='center'>
              <CircularProgress size={50} sx={{ color: '#141d49' }} />
            </Row>
          )}
        </MemberListWrapper>
        <div style={{ flexGrow: '1' }}>
          {selectedMember && (
            <Form form={form} onFinish={handleUpdateMemberInfo}>
              {[DepartmentNamecode.CEO, DepartmentNamecode.HCNS].includes(
                currentOrgInfo?.memberDepartmentCode as DepartmentNamecode,
              ) &&
                selectedMember?.status === MemberStatus.ACTIVE &&
                (isEditing ? (
                  <Row justify='start'>
                    <CancelButton onClick={() => setIsEditing(false)}>
                      Hủy
                    </CancelButton>
                    <SaveButton disabled={updating} htmlType='submit'>
                      Lưu
                    </SaveButton>
                    {updating && (
                      <CircularProgress
                        size={20}
                        sx={{ color: '#141d49', marginTop: '5px' }}
                      />
                    )}
                  </Row>
                ) : (
                  <Row justify='space-between'>
                    <EditButton onClick={handleStartEdit}>Chỉnh sửa</EditButton>
                    <Popover
                      placement='bottomRight'
                      content={popoverMemberMoreActionContent}
                      trigger='click'
                      overlayInnerStyle={{ borderRadius: 20 }}
                    >
                      <MoreHoriz
                        style={{
                          fontSize: 40,
                          color: '#1274e0',
                          cursor: 'pointer',
                        }}
                      />
                    </Popover>
                  </Row>
                ))}
              <MemberDetailInfo
                memberInfo={selectedMember}
                departmentList={departmentList}
                groupList={groupList}
                isEditing={isEditing}
              />
            </Form>
          )}
        </div>
      </Row>
    </div>
  );
};

const MemberListWrapper = styled.div`
  width: 25vw;
  max-width: 400px;
  min-width: 200px;
  max-height: calc(100vh - 200px);
  overflow: auto;
  padding-right: 10px;
  margin: 0 15px;
`;

const MemberInfoWrapper = styled(Row)`
  margin-top: 5px;
  padding: 10px 15px;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    background-color: #eee;
  }
`;

const MemberName = styled.div`
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  -webkit-box-orient: vertical;
  word-wrap: break-word;
  width: calc(100% - 45px);
`;

const HrButton = styled.div`
  width: max-content;
  font-size: 20px;
  font-weight: 600;
  margin: 0 0 30px 5px;
  cursor: pointer;
`;

const EditButton = styled(HrButton)`
  color: #1274e0;

  &:hover {
    color: #8da8c5;
  }
`;

const CancelButton = styled(HrButton)`
  color: red;

  &:hover {
    color: #e58282;
  }
`;

const SaveButton = styled(Button)`
  width: max-content;
  font-size: 20px;
  font-weight: 600;
  margin-left: 20px;
  cursor: pointer;
  border-width: 0;
  color: #1274e0;

  &:hover {
    color: #8da8c5 !important;
  }
`;

const ButtonInPopover = styled.div`
  font-size: 22px;
  font-weight: 700;
  padding: 7px 12px;
  cursor: pointer;
  border-radius: 8px;

  &:hover {
    background-color: #dedede;
  }
`;
