import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, Grid, CircularProgress } from '@mui/material';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';

import styles from '../home.module.css';
import { ResponseDataType, getRequest } from 'api';
import { toast } from 'react-toastify';
import { orgPath } from 'constant/path';
import { circular_progress_sx } from 'constant/styled';
import { CreateStreamButton, HorizontalLine } from 'Components';
import { card_sx, grid_item_sx } from './styled';
import { useAppContext } from 'Context/AppContext';
import { DepartmentNamecode } from 'constant/department';

interface NumberInHomeDataType {
  numberOfProposal: number;
  numberOfRequest: number;
  numberOfTask: number;
  numberOfProposalInOrg: number;
  numberOfRequestInOrg: number;
  numberOfTaskInOrg: number;
}

export const OrgHome = () => {
  const navigate = useNavigate();
  const { currentOrg, currentOrgInfo } = useAppContext();
  const [numberOfProposal, setNumberOfProposals] = useState<number>();
  const [numberOfRequest, setNumberOfRequest] = useState<number>();
  const [numberOfTask, setNumberOfTask] = useState<number>();
  const [numberOfProposalInOrg, setNumberOfProposalInOrg] = useState<number>();
  const [numberOfRequestInOrg, setNumberOfRequestInOrg] = useState<number>();
  const [numberOfTaskInOrg, setNumberOfTaskInOrg] = useState<number>();

  useEffect(() => {
    const GetNumberOfProposal = async () => {
      const response: ResponseDataType<NumberInHomeDataType> = await getRequest(
        `/${orgPath}/${currentOrg}/number-in-home`,
      );

      if (response.code) {
        toast.error(response.message);
      } else {
        setNumberOfProposals(response.data?.numberOfProposal);
        setNumberOfRequest(response.data?.numberOfRequest);
        setNumberOfTask(response.data?.numberOfTask);
        setNumberOfProposalInOrg(response.data?.numberOfProposalInOrg);
        setNumberOfRequestInOrg(response.data?.numberOfRequestInOrg);
        setNumberOfTaskInOrg(response.data?.numberOfTaskInOrg);
      }
    };

    GetNumberOfProposal();
  }, [currentOrg, navigate]);

  return (
    <div className={styles.home_wrapper}>
      <Grid container spacing={5}>
        <Grid item sx={grid_item_sx}>
          <Link style={{ textDecoration: 'none' }} to='/proposal-list'>
            <Card sx={card_sx}>
              <p className={styles.card_title}>Đề xuất</p>
              <p className={styles.card_number}>
                {numberOfProposal ?? (
                  <CircularProgress sx={circular_progress_sx} />
                )}
              </p>
              <Grid container justifyContent='flex-end'>
                <RateReviewOutlinedIcon />
              </Grid>
            </Card>
          </Link>
        </Grid>
        <Grid item sx={grid_item_sx}>
          <Link style={{ textDecoration: 'none' }} to='/request-list'>
            <Card sx={card_sx}>
              <p className={styles.card_title}>Đề nghị</p>
              <p className={styles.card_number}>
                {numberOfRequest ?? (
                  <CircularProgress sx={circular_progress_sx} />
                )}
              </p>
              <Grid container justifyContent='flex-end'>
                <RateReviewOutlinedIcon />
              </Grid>
            </Card>
          </Link>
        </Grid>
        <Grid item sx={grid_item_sx}>
          <Link style={{ textDecoration: 'none' }} to='/task-list'>
            <Card sx={card_sx}>
              <p className={styles.card_title}>Task</p>
              <p className={styles.card_number}>
                {numberOfTask ?? <CircularProgress sx={circular_progress_sx} />}
              </p>
              <Grid container justifyContent='flex-end'>
                <RateReviewOutlinedIcon />
              </Grid>
            </Card>
          </Link>
        </Grid>
      </Grid>
      {currentOrgInfo?.memberDepartmentCode === DepartmentNamecode.QC && (
        <>
          <HorizontalLine
            weight='00'
            style={{ marginTop: 40, marginBottom: 20 }}
          />
          <div
            style={{
              color: '#141D49',
              fontWeight: 600,
              paddingBottom: 10,
              borderBottom: '2px solid #1968FC',
              marginBottom: 30,
              width: 'max-content',
            }}
          >
            Tổng quan tổ chức
          </div>
          <Grid container spacing={5}>
            <Grid item sx={grid_item_sx}>
              <Link
                style={{ textDecoration: 'none' }}
                to='/all-org-proposal-list'
              >
                <Card sx={card_sx}>
                  <p className={styles.card_title}>Đề xuất</p>
                  <p className={styles.card_number}>
                    {numberOfProposalInOrg ?? (
                      <CircularProgress sx={circular_progress_sx} />
                    )}
                  </p>
                  <Grid container justifyContent='flex-end'>
                    <RateReviewOutlinedIcon />
                  </Grid>
                </Card>
              </Link>
            </Grid>
            <Grid item sx={grid_item_sx}>
              <Link
                style={{ textDecoration: 'none' }}
                to='/all-org-request-list'
              >
                <Card sx={card_sx}>
                  <p className={styles.card_title}>Đề nghị</p>
                  <p className={styles.card_number}>
                    {numberOfRequestInOrg ?? (
                      <CircularProgress sx={circular_progress_sx} />
                    )}
                  </p>
                  <Grid container justifyContent='flex-end'>
                    <RateReviewOutlinedIcon />
                  </Grid>
                </Card>
              </Link>
            </Grid>
            <Grid item sx={grid_item_sx}>
              <Link style={{ textDecoration: 'none' }} to='/all-org-task-list'>
                <Card sx={card_sx}>
                  <p className={styles.card_title}>Task</p>
                  <p className={styles.card_number}>
                    {numberOfTaskInOrg ?? (
                      <CircularProgress sx={circular_progress_sx} />
                    )}
                  </p>
                  <Grid container justifyContent='flex-end'>
                    <RateReviewOutlinedIcon />
                  </Grid>
                </Card>
              </Link>
            </Grid>
          </Grid>
        </>
      )}
      <CreateStreamButton />
    </div>
  );
};
